import GSAP from 'gsap'

//LOAD IMG
import IMG from './img/*.jpg'
const imgList = IMG

//MARCEL TITLE CREATE SPAN
const title = document.querySelector('h1')

title.innerHTML = title.textContent.replace(/\S/g, '<span>$&</span>')

const homeGallery = document.querySelector('.scrollable')
const homeParagraphe = document.querySelector('.home_text p')
const footer = document.querySelector('footer')
const subTitle = document.querySelector('h2')

const titleAnim = GSAP.timeline({ delay: 2 })

//PAGE INTRO ANIMATION

titleAnim.fromTo(homeGallery, { right: '-25%', autoAlpha: 0 }, { right: '0%', autoAlpha: 1, duration: 1.5 })
titleAnim.fromTo(
  'h1 span',
  {
    'clip-path': 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
  },
  { stagger: { each: 0.1 }, 'clip-path': 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)', duration: 1.5, ease: 'expo.out' },
  '-=1'
)
titleAnim.fromTo(
  subTitle,
  {
    autoAlpha: 0,
    clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
  },
  {
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    autoAlpha: 1,
    duration: 0.5,
  },
  '-=1.5'
)
titleAnim.from(homeParagraphe, { autoAlpha: 0, skewY: 2, yPercent: 10 }, '-=1')
titleAnim.from(footer, { autoAlpha: 0, yPercent: 10 }, '-=1')

//ADD IMG AND CAPTION TO SLIDER
let images = [...document.querySelectorAll('img')]
images.forEach((img, idx) => {
  img.src = `${imgList[idx + 1]}`
  const number = document.createElement('span')
  number.textContent = '0' + (idx + 1)
  img.parentNode.appendChild(number)
})

//GALLERY SLIDER
const scrollable = document.querySelector('.scrollable')
const imgContent = document.querySelector('.img_content')
const imgSections = [...document.querySelectorAll('.img_section')]

imgSections.forEach(imgSection => {
  let clonedSection = imgSection.cloneNode(true)
  imgContent.appendChild(clonedSection)
  clonedSection.classList.add('clone')
})

//SET SCROLL DIMENSION
function init() {
  document.body.style.height = `${imgContent.getBoundingClientRect().height}px`
}
window.addEventListener('resize', init)

//SCROLL EVENT
let target = 1
let reverse = false

document.addEventListener('click', () => (reverse = !reverse))
function scroll() {
  target = window.scrollY

  if (target <= 0) {
    target = imgContent.offsetHeight / 2 - 1
    window.scrollTo(0, target)
  } else if (target >= imgContent.offsetHeight / 2) {
    target = 1
    window.scrollTo(0, target)
  }

  if (reverse) {
    target--
  } else {
    target++
  }
  // window.scrollTo(0, target, 400)
  scrollable.style.transform = `translateY(-${target}px)`
  requestAnimationFrame(scroll)
}
init()
document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    window.scrollTo(0, 1)
  }, 200)
  scroll()
})

//LOADER
const loader = document.querySelector('.loader')
const logoLoader = document.querySelector('.logo_loader')

window.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    logoLoader.classList.add('active')
  }, 400)

  setTimeout(() => {
    loader.style.top = '-100vh'
    logoLoader.style.opacity = 0
  }, 1000)
})
